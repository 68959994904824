<template>
  <div>
    <b-skeleton v-if="loading" animation="wave" width="100%" height="300px" />
    <table v-else class="table table-no-horizontal-border">
      <thead>
        <tr>
          <th
            v-for="schedule in appointmentSchedules"
            :key="schedule.id"
            class="text-center"
          >
            {{ schedule.day | formatDay }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="schedule in appointmentSchedules" :key="schedule.id">
            <b-form-select
              v-model="schedule.start_hour"
              :options="timeOptions"
              :class="isDarkSkin ? 'border-0' : ''"
              :style="
                isDarkSkin
                  ? 'background-color: #292D32;'
                  : 'background-color: #FEFFFE;'
              "
              v-b-tooltip.hover.v-primary="'Pick start hour'"
              @input="changeHour(schedule.id)"
            />
          </td>
        </tr>

        <tr>
          <td v-for="schedule in appointmentSchedules" :key="schedule.id">
            <b-form-select
              v-model="schedule.end_hour"
              :options="timeOptions"
              :class="isDarkSkin ? 'border-0' : ''"
              :style="{
                border: arrErrors.includes(schedule.id) ? '1px solid red' : '',
                'background-color': isDarkSkin ? '#292D32' : '#FEFFFE',
              }"
              v-b-tooltip.hover.v-primary="'Pick end hour'"
              @input="changeHour(schedule.id)"
            />
            <div class="d-flex justify-content-start w-auto">
              <small
                v-if="arrErrors.includes(schedule.id)"
                class="text-danger w-auto"
                >Greater than Start Hour</small
              >
            </div>
          </td>
        </tr>
        <tr>
          <td v-for="schedule in appointmentSchedules" :key="schedule.id">
            <div class="d-flex" style="justify-content: space-evenly">
              <span
                class="cursor-pointer bg-primary"
                style="padding: 8px; border-radius: 10px"
                @click="copyHours(schedule)"
              >
                <feather-icon
                  v-b-tooltip.hover
                  icon="CopyIcon"
                  size="20"
                  title="Copy hour range to other days"
                  style="color: white !important"
                />
              </span>
              <span
                :class="{
                  'cursor-pointer': schedule.modified,
                  'span-disabled': !schedule.modified,
                }"
                class="bg-warning"
                style="padding: 8px; border-radius: 10px"
                @click="schedule.modified && saveDay(schedule)"
              >
                <feather-icon
                  v-b-tooltip.hover
                  icon="SaveIcon"
                  size="20"
                  title="Save this day"
                  style="color: white !important"
              /></span>
              <span
                class="cursor-pointer"
                style="
                  background-color: #8e5fe9;
                  padding: 8px;
                  border-radius: 10px;
                "
                @click="getTracking(schedule.day)"
              >
                <feather-icon
                  v-b-tooltip.hover
                  icon="ListIcon"
                  size="20"
                  title="Tracking of this day"
                  style="color: white !important"
              /></span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-end align-items-center mt-3">
      <button
        type="button"
        class="btn btn-success"
        @click="saveSchedule()"
        :disabled="disableBtn"
      >
        <strong>SAVE</strong>
      </button>
    </div>

    <b-modal v-model="modal" hide-footer :title="`Tracking ${modalTitle}`">
      <b-table striped hover :fields="fields" :items="selectedDay">
        <template #cell(updated_by)="data">
          <div v-if="data.item.created_at">
            <span>
              <feather-icon icon="UserIcon" size="20" />
              {{ data.item.updated_by }}
            </span>
            <br />
            <span>
              <feather-icon icon="CalendarIcon" size="20" />
              {{ data.item.created_at | myGlobal }}
            </span>
          </div>
          <span v-else> - </span>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: true,
      appointmentSchedules: {},
      timeOptions: [],
      arrErrors: [],
      newStartHour: null,
      newEndHour: null,
      modal: false,
      modalTitle: "",
      fields: ["updated_by", "start_hour", "end_hour"],
      selectedDay: {},
      disableBtn: true,
    };
  },
  async created() {
    await this.search();
    this.timeOptions = this.generateTimeOptions(null, null, 30); // start, end, interval
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    changeHour(id) {
      const day = this.appointmentSchedules.find((item) => item.id === id);
      if (day) {
        day.modified = true;
      }
      if (this.getModifiedDays().length > 1) {
        this.disableBtn = false;
      }
    },
    getModifiedDays() {
      return this.appointmentSchedules.filter((item) => item.modified);
    },
    addModifiedValue(bool) {
      this.appointmentSchedules.forEach((item) => {
        item.modified = bool;
      });
    },
    formatDayFunction(value) {
      const days = {
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
        7: "Sunday"
      };
      return days[value];
    },
    async search() {
      try {
        this.loading = true;
        const res = await SettingsServiceSp.getAppointmentSchedule();
        if (res.status === 200) {
          this.appointmentSchedules = res.data;
        }
        this.addModifiedValue(false);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
      return [];
    },
    async copyHours(schedule) {
      if (!this.validateSchedule(schedule.id)) {
        this.showInfoSwal(
          "You are trying to copy an invalid range to all days. Try again"
        );
        return;
      }
      const result = await this.showConfirmSwal();
      if (!result.isConfirmed) return;
      for (const element of this.appointmentSchedules) {
        element.start_hour = schedule.start_hour;
        element.end_hour = schedule.end_hour;
      }
      this.addModifiedValue(true);
    },
    async saveDay(schedule) {
      try {
        if (!this.validateSchedule(schedule.id)) {
          return;
        }
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const payload = {
            id: schedule.id,
            start: schedule.start_hour,
            end: schedule.end_hour,
            user_id: this.currentUser.user_id,
            type: 1,
          };
          const response = await SettingsServiceSp.updateAppointmentDay(
            payload
          );
          if (response.status === 200) {
            this.showSuccessSwal();
            await this.search();
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.modified = false;
        this.removePreloader();
      }
    },
    async saveSchedule() {
      try {
        if (!this.validateSchedule()) {
          return;
        }
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const payload = {
            schedules: JSON.stringify(this.getModifiedDays()),
            user_id: this.currentUser.user_id,
            type: 2,
          };
          const response = await SettingsServiceSp.updateAppointmentWeek(
            payload
          );
          if (response.status === 200) {
            this.showSuccessSwal();
            await this.search();
            this.addModifiedValue(false);
            this.disableBtn = true;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.modified = false;
        this.removePreloader();
      }
    },
    async getTracking(day) {
      try {
        this.addPreloader();
        const response = await SettingsServiceSp.getScheduleTracking({ day });
        if (response.status === 200) {
          this.selectedDay = response.data;
          this.removePreloader();
          this.modalTitle = this.formatDayFunction(day);
          this.modal = true;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
    validateSchedule(id = null) {
      let status = true;
      this.arrErrors = [];
      if (id) {
        const element = this.appointmentSchedules.find(
          (item) => item.id === id
        );
        if (element.end_hour <= element.start_hour) {
          status = status === true ? false : status;
          this.arrErrors.push(element.id);
        }
      } else {
        this.appointmentSchedules.forEach((element) => {
          if (element.end_hour < element.start_hour) {
            status = status ? false : status;
            this.arrErrors.push(element.id);
          }
        });
      }
      return status;
    },
  },
};
</script>

<style scoped>
.table-no-horizontal-border td {
  border-top: none;
  border-bottom: none;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.span-disabled {
  opacity: 0.4;
}
</style>