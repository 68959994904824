<template>
  <div>
    <b-modal
      v-model="showModal"
      size="lg"
      title-class="h5 text-white font-weight-bolder"
      :title="`${titleModal.toUpperCase()}`"
      @hide="closeModal"
      hide-footer
    >
      <b-table
        ref="trackingTable"
        :items="myProvider"
        class="blue-scrollbar"
        :fields="myFields"
        :busy="isBusy"
        primary-key="id"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(old_expenses)="data">
          $ {{ data.item.old_expenses | currency }}
        </template>
        <template #cell(active)="data">
          <b-badge
            class="w-75"
            pill
            :variant="data.item.is_active ? 'success' : 'danger'"
          >
            {{ data.item.is_active ? "ACTIVE" : "INACTIVE" | myFontCapitalize }}
          </b-badge>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>
  <script>
import TrackingFields from "@/views/specialists/sub-modules/financial-approval/views/settings/data/tracking.js";
import SettingService from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";

export default {
  props: {
    item: Object,
    tabSelected: String,
    subTabSelected: String,
  },

  data() {
    return {
      showModal: false,
      isBusy: false,
      TrackingFields,
      typencrData: [],
    };
  },
  computed: {
    myFields() {
      return this.TrackingFields.filter((field) => field.visible);
    },
    titleModal() {
      const titleMappings = {
        manual: `NCR PROVIDER ${this.item.name}`,
        questionnaire: `${this.item.name}`,
        settings: `${this.item.name}`,
        default: "",
      };
      const title = titleMappings[this.tabSelected] || titleMappings["default"];
      return `TRACKING OF ${title}`;
    },
  },
  created() {
    this.myFilteredFields();
    this.openModal();
  },
  methods: {
    myFilteredFields() {
      this.filterColumn(
        "name",
        this.tabSelected === "manual" ||
          this.tabSelected === "questionnaire" ||
          this.tabSelected === "settings"
      );
      this.filterColumn("old_expenses", this.tabSelected === "manual");
      this.filterColumn("new_expenses", this.tabSelected === "manual");
      this.filterColumn(
        "active",
        this.tabSelected === "questionnaire" ||
          this.tabSelected === "settings"
      );
    },
    filterColumn(key, bool) {
      let field = this.TrackingFields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },

    getStatus(status) {
      if (status == 0) {
        return "INACTIVE";
      }
      if (status == 1) {
        return "ACTIVE";
      }
      if (status === "ACTIVE") {
        return status;
      }
      if (status === "INACTIVE") {
        return status;
      }
    },

    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.$emit("close");
    },
    async myProvider() {
      const formData = {
        itemId: this.item.id,
      };
      try {
        const methodMapping = {
          manual: "getManualNcrTypeTrack",
          questionnaire: "getTrackingQuestionnaireItem",
          settings: "getTrackingAppointmentCancelMotives",
        };
        const methodService = methodMapping[this.tabSelected];
        const { data } = await SettingService[methodService](formData);
        this.typencrData = data;
        return this.typencrData;
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
