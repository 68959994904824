import moment from "moment";
export default [
  {
    key: "name",
    label: "Name",
    tdClass: "pt-1",
    visible: true,
  },
  {
    key: "description",
    label: "Description",
    tdClass: "pt-1",
    visible: false,
  },
  {
    key: "created_by",
    label: "CREATED BY",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "old_expenses",
    label: "OLD PRICE",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "new_expenses",
    label: "NEW PRICE",
    tdClass: "pt-1 px-0 text-center",
    formatter: (value) => `$ ${value}`,
    thClass: "text-center",
    visible: true,
  },
  {
    key: "created_at",
    label: "CREATED AT",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    formatter: (value) => {
      if (value) return moment(value).format("MM/DD/YYYY hh:mm A");
      return "-";
    },
    visible: true,
  },
  {
    key: "active",
    label: "ACTIVE",
    tdClass: "pt-1 px-0 text-center",
    thClass: "text-center",
    visible: true,
  },
  {
    key: "min_price",
    label: "Min Price",
    tdClass: "pt-1 px-0 text-center text-success",
    thClass: "text-center",
    formatter: (value) => `$ ${value}`,
    visible: false,
  },
];
