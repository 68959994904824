<template>
  <div>
    <div class="questionnaire-main">
      <div class="questionnaire-sidebar">
        <div class="menu-sidebar" :class="{ dark: isDarkSkin }">
          <div>
            <p class="title-sidebar">CREDIT CARDS</p>
            <ul class="items-sidebar" :class="{ dark: isDarkSkin }">
              <li
                v-for="tab in menuCreditCards"
                :key="tab.id"
                @click="changeTab(tab)"
                :class="{ active: tab.id == category }"
              >
                {{ tab.name }}
              </li>
            </ul>
          </div>
          <hr />
          <div>
            <p class="title-sidebar">APPOINTMENTS</p>
            <ul class="items-sidebar" :class="{ dark: isDarkSkin }">
              <li
                v-for="tab in menuAppointments"
                :key="tab.id"
                @click="changeTab(tab)"
                :class="{ active: tab.id == category }"
              >
                {{ tab.name }}
              </li>
            </ul>
          </div>
          <hr />
          <div>
            <p class="title-sidebar">SETTINGS</p>
            <ul class="items-sidebar" :class="{ dark: isDarkSkin }">
              <li
                v-for="tab in menuSettings"
                :key="tab.id"
                @click="changeTab(tab)"
                :class="{ active: tab.id == category }"
              >
                {{ tab.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <main v-if="category == 'schedule'" class="schedule-main">
        <AppointmentsSchedule />
      </main>

      <main v-else class="body-main">
        <filter-slot
          :filter="[]"
          :total-rows="FilterSlot.totalRows"
          :paginate="FilterSlot.paginate"
          :start-page="FilterSlot.startPage"
          :to-page="FilterSlot.toPage"
          :filter-principal="FilterSlot.filterPrincipal"
          @reload="refreshTable"
        >
          <template #buttons>
            <b-button
              variant="primary"
              class="ml-2"
              @click="openCreateModal()"
              v-if="category !== 'types'"
            >
              <tabler-icon icon="PlusIcon" />
              Add
            </b-button>
          </template>
          <b-table
            ref="questionnaireTable"
            slot="table"
            class="blue-scrollbar"
            :items="myProvider"
            :fields="visibleFields"
            primary-key="id"
            responsive="md"
            sticky-header="50vh"
            no-provider-filtering
            :per-page="FilterSlot.paginate.perPage"
            :current-page="FilterSlot.paginate.currentPage"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>

            <template #cell(is_active)="{ item }">
              <b-badge
                :variant="item.is_active ? 'light-success' : 'light-warning'"
                @click="changeStatus(item)"
                class="clickable"
              >
                {{ item.is_active ? "ACTIVE" : "INACTIVE" }}
                <feather-icon
                  icon="RepeatIcon"
                  :class="item.is_active ? 'text-success' : 'text-warning'"
                  size="22"
                  v-b-tooltip.hover.top="item.is_active ? 'Disable' : 'Enable'"
                />
              </b-badge>
            </template>

            <template #cell(tracking)="{ item }">
              <b-button
                class="p-0"
                variant="outline"
                v-b-tooltip.hover.top="'Tracking'"
                @click="openTrackingModal(item)"
              >
                <feather-icon
                  icon="ListIcon"
                  size="17"
                  class="text-primary clickable"
                />
              </b-button>
            </template>

            <template #cell(actions)="{ item }">
              <div class="d-flex align-items-center justify-content-center">
                <b-button
                  class="p-0"
                  variant="outline"
                  v-b-tooltip.hover.top="'Edit'"
                  @click="editModal(item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="17"
                    class="text-info clickable"
                  />
                </b-button>
                <b-button
                  v-if="category === 'motives'"
                  class="p-0"
                  variant="outline"
                  v-b-tooltip.hover.top="'Delete'"
                  @click="deleteItem(item.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="text-danger"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </filter-slot>
      </main>
    </div>

    <CreateModal
      v-if="showCreateModal"
      :itemTypeNrc="itemTypeNrc"
      :mode="mode"
      :tabSelected="tabSelected"
      :subTabSelected="String(category)"
      :menuItem="menuItem"
      @close="closeCreateModal"
      @refresh="refreshTable"
    />

    <TrackingModal
      v-if="showTrackingModal"
      :item="itemTrack"
      :tabSelected="tabSelected"
      :subTabSelected="String(category)"
      @close="showTrackingModal = false"
    />
  </div>
</template>

<script>
// STORE
import { mapGetters } from "vuex";

// SERVICES
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";

// MODALS
import CreateModal from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/CreateModal.vue";
import TrackingModal from "@/views/specialists/sub-modules/financial-approval/views/settings/views/modals/TrackingModal.vue";

// LIBRARIES
import moment from "moment";

// COMPONENTS
import AppointmentsSchedule from "@/views/specialists/sub-modules/financial-approval/views/settings/views/components/AppointmentsSchedule.vue";

export default {
  data() {
    return {
      FilterSlot: {
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: null,
        toPage: null,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
      },
      fields: [
        {
          key: "name",
          label: "option",
          visible: true,
        },
        {
          key: "is_active",
          label: "Status",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "created_by",
          label: "Created by",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "created_at",
          label: "Created at",
          thClass: "text-center",
          tdClass: "text-center",
          formatter: (value) => {
            if (value) return moment(value).format("MM/DD/YYYY hh:mm A");
            return "-";
          },
          visible: true,
        },
        {
          key: "price",
          label: "Min Price",
          thClass: "text-center",
          tdClass: "text-center",
          formatter: (value) => `$ ${value}`,
          visible: false,
        },
        {
          key: "tracking",
          label: "Tracking",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "actions",
          label: "Actions",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
      ],
      items: [],
      menuCategories: [],
      category: null,
      showCreateModal: false,
      mode: null,
      itemTypeNrc: {},
      itemTrack: null,
      showTrackingModal: false,
      menuItem: null,
      tabSelected: null,
    };
  },
  components: {
    CreateModal,
    TrackingModal,
    AppointmentsSchedule,
  },
  computed: {
    ...mapGetters({
      theme: "appConfig/skin",
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    menuCreditCards() {
      return this.menuCategories.filter((item) => item.type == "CREDIT CARDS");
    },
    menuAppointments() {
      return this.menuCategories.filter((item) => item.type == "APPOINTMENTS");
    },
    menuSettings() {
      return [
        // { id: "types", name: "Types of Appointments" },
        // { id: "motives", name: "Cancellation Motives", type: "settings" },
        { id: "schedule", name: "Attention Hours", type: "settings" },
      ];
    },
  },
  async created() {
    await this.getSidebarTabs();
    this.filterColumn("created_by", this.category == 4);
  },
  methods: {
    async getSidebarTabs() {
      try {
        const { data } = await SettingsServiceSp.getQuestionnaireCategories();
        this.menuCategories = data;
        this.category = data[0].id;
        this.menuItem = data[0].name;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    async myProvider() {
      if (this.category == "schedule") return; // no table here, schedule instead
      try {
        const formdata = {
          page: this.FilterSlot.paginate.currentPage,
          perPage: this.FilterSlot.paginate.perPage,
          search: this.FilterSlot.filterPrincipal.model,
          category: this.category,
        };

        let method = null;
        switch (this.category) {
          case "types":
            method = "getAppointmentTypes";
            break;

          case "motives":
            method = "getAppointmentCancellationMotives";
            break;

          default:
            method = "getQuestionnaireItems";
        }

        let data = await SettingsServiceSp[method](formdata);
        this.FilterSlot.startPage = data.from;
        this.FilterSlot.paginate.currentPage = data.current_page;
        this.FilterSlot.paginate.perPage = data.per_page;
        this.FilterSlot.totalRows = data.total;
        this.FilterSlot.toPage = data.to;

        return data.data;
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async changeTab(tab) {
      const previousTab = this.category;
      if (this.category !== tab.id) {
        this.FilterSlot.filterPrincipal.model = "";
        this.category = tab.id;
        this.menuItem = tab.name;
        this.filterColumn("created_by", this.category == 4);
        this.filterColumn("price", this.category == "types");
        this.fields[0].label =
          this.category == "types"
            ? "Type of appointment"
            : this.category == "motives"
            ? "Cancellation motive"
            : "Option";
        if (previousTab !== "schedule") {
          this.refreshTable();
        }
      }
    },
    async changeStatus(data) {
      const confirm = await this.showConfirmSwal(
        `Are you sure to change the status?`,
        null
      );

      if (confirm.isConfirmed) {
        let newStatus = !data.is_active;
        const formdata = {
          id: data.id,
          isActive: newStatus,
          userId: this.currentUser.user_id,
        };
        try {
          const method =
            this.category == "types"
              ? "updateAppointmentType"
              : this.category == "motives"
              ? "updateAppointmentCancellationMotive"
              : "updateQuestionnaireItem";
          let { data } = await SettingsServiceSp[method](formdata);
          if (data.success) {
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
            this.refreshTable();
          } else {
            this.showToast(
              "danger",
              "top-right",
              "Failed!",
              "XIcon",
              "Failed operation"
            );
          }
        } catch (error) {
          this.showErrorSwal(error);
        }
      }
    },
    async refreshTable() {
      this.$refs["questionnaireTable"].refresh();
    },
    openCreateModal() {
      this.mode = "Create";
      this.tabSelected =
        this.category == "motives" ? "settings" : "questionnaire";
      this.showCreateModal = true;
    },
    closeCreateModal() {
      this.showCreateModal = false;
      this.itemTypeNrc = {};
      this.type = null;
    },
    editModal(data) {
      this.mode = "Update";
      this.tabSelected =
        this.category == "motives" ? "settings" : "questionnaire";
      this.showCreateModal = true;
      this.itemTypeNrc = data;
    },
    openTrackingModal(data) {
      this.tabSelected =
        this.category == "motives" ? "settings" : "questionnaire";
      this.itemTrack = data;
      this.showTrackingModal = true;
    },
    async deleteItem(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const data = await SettingsServiceSp.deleteCancellationMotive({
            id,
            user_id: this.currentUser.user_id,
          });
          if (data.status === 200) {
            this.showSuccessSwal();
            this.refreshTable();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.questionnaire-main {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  .questionnaire-sidebar {
    width: 15%;
    .menu-sidebar {
      position: sticky;
      top: 5rem;
      margin-top: 1rem;
      padding: 1.5rem 1rem;
      background-color: #f5f5f5;
      border-radius: 1rem;
      &.dark {
        background-color: #1e1e1e;
      }
    }
  }
  .body-main,
  .schedule-main {
    width: 85%;
    padding: 0rem 1.5rem;
  }
}

.title-sidebar {
  font-size: calc(1.1rem * var(--scale));
  font-weight: bold;
  margin: 0;
  padding: 1rem;
}

.items-sidebar {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    font-size: calc(1rem * var(--scale));
    padding: 0.8rem;
    cursor: pointer;
    &:hover {
      background-color: #5cacf8;
      color: #fff;
      border-radius: 0.5rem;
    }
    &.active {
      background-color: #0077e6;
      color: #fff;
      border-radius: 0.5rem;
    }
  }
  li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
</style>